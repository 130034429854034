import { TENANTS } from "../tenants/constants"
import { Tenants } from "../tenants/types"
import { propriooZipCodes } from "./data/fr_proprioo"

type ZipCodeMatchers = {
  [countryCode: string]: {
    [city: string]: { includes: (zipcode: string) => boolean }
  }
}
const fromRegex = (regex: RegExp): ZipCodeMatchers[string][string] => ({
  includes: (zipcode: string) => regex.test(zipcode),
})

const BARCELONA_SUPPORTED_ZIPCODES = [
  "08003",
  "08004",
  "08005",
  "08006",
  "08007",
  "08008",
  "08009",
  "08010",
  "08011",
  "08012",
  "08013",
  "08014",
  "08015",
  "08016",
  "08017",
  "08018",
  "08020",
  "08021",
  "08022",
  "08023",
  "08024",
  "08025",
  "08026",
  "08027",
  "08028",
  "08029",
  "08030",
  "08031",
  "08034",
  "08036",
  "08037",
  "08041",
  "08201",
  "08202",
  "08204",
  "08205",
  "08206",
  "08207",
  "08208",
  "08221",
  "08222",
  "08223",
  "08224",
  "08225",
  "08226",
  "08227",
  "08901",
  "08902",
  "08903",
  "08904",
  "08905",
  "08906",
  "08907",
  "08908",
  "08911",
  "08912",
  "08913",
  "08914",
  "08915",
  "08916",
  "08917",
  "08918",
  "08921",
  "08923",
  "09817",
]

const MADRID_SUPPORTED_ZIPCODES = [
  "28001",
  "28002",
  "28003",
  "28004",
  "28005",
  "28006",
  "28007",
  "28008",
  "28009",
  "28010",
  "28011",
  "28012",
  "28013",
  "28014",
  "28015",
  "28016",
  "28017",
  "28018",
  "28019",
  "28020",
  "28021",
  "28022",
  "28023",
  "28024",
  "28025",
  "28026",
  "28027",
  "28028",
  "28029",
  "28030",
  "28031",
  "28032",
  "28033",
  "28034",
  "28035",
  "28036",
  "28037",
  "28038",
  "28039",
  "28040",
  "28041",
  "28042",
  "28043",
  "28044",
  "28045",
  "28046",
  "28047",
  "28048",
  "28049",
  "28050",
  "28051",
  "28052",
  "28053",
  "28054",
  "28055",
  "28070",
  "28071",
  "28080",
  "28082",
  "28083",
  "28085",
  "28086",
  "28087",
  "28801",
  "28802",
  "28803",
  "28804",
  "28805",
  "28806",
  "28807",
  "28830",
  "28850",
  "28851",
  "28931",
  "28932",
  "28933",
  "28934",
  "28935",
  "28936",
  "28937",
  "28938",
  "28980",
  "28981",
  "28982",
  "28983",
  "28984",
]

const MILAN_OUTSKIRTS = [
  "20006",
  "20015",
  "20016",
  "20017",
  "20025",
  "20026",
  "20030",
  "20037",
  "20039",
  "20054",
  "20068",
  "20092",
  "20093",
  "20094",
  "20097",
  "20098",
  "20099",
  "20832",
  "20850",
  "20900",
  "29861",
].join("|")

const ROME_OUTSKIRTS = [
  "00012",
  "00040",
  "00042",
  "00043",
  "00045",
  "00047",
  "00048",
  "00050",
  "00052",
  "00054",
  "00071",
].join("|")

const MALAGA_ZIP_CODES = [
  "29008",
  "29190",
  "29009",
  "29002",
  "29003",
  "29005",
  "29004",
  "29591",
  "29006",
  "29007",
  "29012",
  "29017",
  "29011",
  "29010",
  "29018",
  "29013",
  "29014",
  "29015",
  "29016",
  "29001",
  "29140",
  "29590",
  "29720",
  "29649",
  "29130",
  "29650",
  "29193",
  "29730",
  "29639",
  "29620",
  "29630",
  "29630",
  "29720",
  "29196",
  "29631",
]

const SEVILLA_ZIP_CODES = [
  "41010",
  "41011",
  "41018",
  "41009",
  "41005",
  "41003",
  "41004",
  "41002",
  "41006",
  "41007",
  "41008",
  "41013",
  "41015",
  "41017",
  "41016",
  "41019",
  "41020",
  "41011",
  "41014",
  "41092",
  "41012",
  "41001",
  "41908",
  "41920",
  "41120",
  "41950",
  "41940",
  "41930",
  "41900",
  "41910",
]

const milanRegex = new RegExp(`201(2[1-9]|[3-5][0-9]|6[0-2])$|${MILAN_OUTSKIRTS}`)
const romeRegex = new RegExp(`001(1[89]|[2-9][0-9])$|${ROME_OUTSKIRTS}`)

const supportedZipCodeMatchers: ZipCodeMatchers = {
  ES: {
    madrid: MADRID_SUPPORTED_ZIPCODES,
    barcelona: BARCELONA_SUPPORTED_ZIPCODES,
    malaga: MALAGA_ZIP_CODES,
    sevilla: SEVILLA_ZIP_CODES,
  },
  IT: {
    bologna: fromRegex(/^401(2[1-9]|3[0-9]|4[01])$|^40033$|^40068$/),
    firenze: fromRegex(/^501(2[1-9]|3[0-9]|4[0-5])$|^50018$|^50012$/),
    milano: fromRegex(milanRegex),
    roma: fromRegex(romeRegex),
    torino: fromRegex(/^101(2[1-9]|[34][0-9]|5[0-6])$|^10093$|^10095$|^10098$/),
  },
  FR: {
    paris: propriooZipCodes,
  },
}

export function validateUCSCZipCode(country: keyof ZipCodeMatchers): boolean {
  return country === "IT"
}

export function validateCasavoZipCode(country: keyof ZipCodeMatchers, zipCode: string): boolean {
  // in portugal zipcodes are in the format {number}-{number}, for now we only care about the first part
  if (zipCode.includes("-")) {
    zipCode = zipCode.split("-")[0]
  }

  if (!supportedZipCodeMatchers[country]) {
    return false
  }
  return Object.values(supportedZipCodeMatchers[country]).some((city) => city.includes(zipCode))
}

export function validateZipCode({
  country,
  zipCode,
  tenant,
}: {
  country: keyof ZipCodeMatchers
  zipCode: string
  tenant: Tenants
}): boolean {
  switch (tenant) {
    case TENANTS.casavo:
      return validateCasavoZipCode(country, zipCode)
    case TENANTS.ucsc:
      return validateUCSCZipCode(country)
  }
}

/**
 * @returns the "metropolitan" city slug *if* it's an area we're handling/mapped in supportedZipCodeMatchers
 * for outskirts it returns the metropolitan city, for example for sesto san giovanni => it/milano
 */
export const getMetropolitanCitySlug = (countryCode: string, zipCode: string) => {
  // in portugal zipcodes are in the format {number}-{number}, for now we only care about the first part
  if (zipCode.includes("-")) {
    zipCode = zipCode.split("-")[0]
  }

  const countrySupportedZipCodes = supportedZipCodeMatchers[countryCode]
  if (!countrySupportedZipCodes) {
    return null
  }

  const entry = Object.entries(countrySupportedZipCodes).find(([city, matcher]) => matcher.includes(zipCode))

  if (entry) {
    return `${countryCode.toLowerCase()}/${entry[0]}`
  }

  return null
}
